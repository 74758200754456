import { getParsedJson, getToken, getUserData } from "@iso/lib/helpers/utility";
import actions from "./actions";

const token = getToken();
const uData = getUserData() || {};
const timer = 60;

const initState = {
  idToken: token,
  userData: uData,
  loggedOut: false,
  timer: timer,
  uuid: null,
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        idToken: action.token,
        userData: action.user ? getParsedJson(action.user) : {},
      };
    case actions.LOGOUT: {
      const redirect = "signin";
      return {
        ...initState,
        idToken: null,
        userData: { redirect },
      };
    }
    case actions.SET_LOGGED_OUT:
      return {
        ...state,
        loggedOut: action.loading,
      };
    case actions.SET_USER_OBJ:
      return {
        ...state,
        userData: action.user ? action.user : {},
      };
    case actions.SET_TIMER:
      return {
        ...state,
        timer: action.timer,
      };
    case actions.SET_UUID:
      return {
        ...state,
        uuid: action.uuid,
      };
    default:
      return state;
  }
}
