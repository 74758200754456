// import { getCurrentLanguage } from './config';
const actions = {
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  ACTIVATE_LANG_MODAL: 'ACTIVATE_LANG_MODAL',
  SET_ALL_WORDS: 'SET_ALL_WORDS',
  switchActivation: () => ({
    type: actions.ACTIVATE_LANG_MODAL,
  }),
  changeLanguage: (language) => ({
    type: actions.CHANGE_LANGUAGE,
    language,
    // language: getCurrentLanguage(language),
  }),
  setAllWords: (data) => ({
    type: actions.SET_ALL_WORDS,
    allWords: data,
  }),
};
export default actions;
