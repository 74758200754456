/* eslint-disable default-param-last */
import actions from './actions';

const initState = {
  IOSocket: null,
  socketData: {},
  notification: {},
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_IO:
      return {
        ...state,
        IOSocket: action.data,
        socketData: action.socketData || state.socketData,
      };
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.data,
      };
    default:
      return state;
  }
}
