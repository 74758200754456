// For Role - 'Super Admin', 'Supported Admin', 'User'
// For User Type - 'Admin', 'Super Admin', 'Customer', 'Handyman
const options = [
  // {
  // 	key: 'calendar',
  // 	label: 'sidebar.calendar',
  // 	leftIcon: 'ion-calendar',
  // 	img: 'calendar'
  // },
  // {
  // 	key: 'appointments',
  // 	label: 'sidebar.Appointments',
  // 	leftIcon: 'ion-calendar',
  // 	img: 'calendar'
  // },
  {
    key: "handyman",
    label: "Handyman",
    leftIcon: "ion-android-contacts",
    img: "users",
  },
  {
    key: "customers",
    label: "Customers",
    leftIcon: "ion-person",
    img: "user",
  },
  // {
  // 	key: 'invoices',
  // 	label: 'sidebar.invoice',
  // 	leftIcon: 'ion-clipboard',
  // 	img: 'invoice'
  // },
  // {
  // 	key: 'my-invoices',
  // 	label: 'sidebar.invoice',
  // 	leftIcon: 'ion-clipboard',
  // 	img: 'invoice'
  // },
  {
    key: "email-templates",
    label: "Email Templates",
    leftIcon: "ion-email",
    img: "email",
  },
  // {
  // 	key: 'blogs',
  // 	label: 'sidebar.Blogs',
  // 	leftIcon: 'ion-document-text',
  // 	img: 'blog'
  // },
  {
    key: "services",
    label: "sidebar.Services",
    leftIcon: "ion-settings",
    img: "services",
  },
  {
    key: "cms",
    label: "sidebar.Cms",
    leftIcon: "ion-document-text",
    img: "cms",
  },
  {
    key: "manage-admin",
    label: "sidebar.ManageAdmin",
    leftIcon: "ion-android-contacts",
    img: "users",
  },
  // {
  // 	key: 'languages mgt',
  // 	label: 'sidebar.Languages',
  // 	leftIcon: 'ion-android-globe',
  // 	img: 'globe',
  // 	children: [
  // 		{
  // 			key: 'languages',
  // 			label: 'sidebar.Languages'
  // 		},
  // 		{
  // 			key: 'all-words',
  // 			label: 'sidebar.AllWords'
  // 		}
  // 	]
  // },
  {
    key: "settings",
    label: "themeSwitcher.settings",
    leftIcon: "ion-settings",
    img: "settings",
  },
  // {
  // 	key: 'legal',
  // 	label: 'Legal',
  // 	leftIcon: 'ion-android-globe',
  // 	img: 'file-manager',
  // 	children: [
  // 		{
  // 			type: 'frontend',
  // 			key: 'impressum',
  // 			label: 'Imprint'
  // 		},
  // 		{
  // 			key: 'datenschutz',
  // 			type: 'frontend',
  // 			label: 'Data Protection'
  // 		},
  // 		{
  // 			key: 'allgemeine-geschaeftsbedingungen',
  // 			type: 'frontend',
  // 			label: 'Terms of Service'
  // 		}
  // 	]
  // }
];
export default options;
