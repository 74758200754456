/* eslint-disable no-console */
/* eslint-disable eqeqeq */
import { isObject, isEmpty } from "lodash";
import Notification from "@iso/components/Notification";
import siteConfig from "@iso/config/site.config";
import authActions from "@iso/redux/auth/actions";

const socketIOClient = require("socket.io-client");
const sailsIOClient = require("sails.io.js");

const io = sailsIOClient(socketIOClient);

if (io) {
  io.sails.url = siteConfig.sailsUrl;
  io.sails.autoConnect = false;
}

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const actions = {
  NOTIFICATION_INIT: "NOTIFICATION_INIT",
  SET_IO: "SET_IO",
  SET_NOTIFICATION: "SET_NOTIFICATION",

  initilization: () => (dispatch, getState) => {
    const { IOSocket } = getState().Notification;
    const token = getState().Auth.idToken;

    if (!IOSocket) {
      const socket = io.sails.connect();
      // console.log('socket=====<>');
      // console.log(socket);
      // console.log('socket.isConnecting()======>');
      // console.log(socket.isConnecting());
      // console.log('socket.isConnected()======>');
      // console.log(socket.isConnected());
      if (socket) {
        const headers = { ...defaultHeaders };
        if (token) headers.Authorization = `Bearer ${token}`;
        const options = {
          method: "POST",
          url: "/notification/connect",
          headers,
        };
        try {
          socket.request(options, (resData) => {
            // console.log('Sails responded with: ', resData);
            // console.log('with headers: ', jwres.headers);
            // console.log('and with status code: ', jwres.statusCode);
            dispatch({
              type: actions.SET_IO,
              data: socket,
              socketData:
                isObject(resData) && isObject(resData.data)
                  ? resData.data
                  : null,
            });
          });
        } catch (err) {
          // console.log(err);
        }

        // RECIEVE NOTIFICATION
        socket.on("notification", (noti) => {
          // console.log('notification===>');
          // console.log(noti);
          if (isObject(noti) && !isEmpty(noti)) {
            dispatch(actions.receiveNoti(noti));
          }
        });

        socket.on("disconnect", () => {
          // console.log('SOCKET DISCONNECTED ====>');
          dispatch({
            type: actions.SET_IO,
            data: null,
            socketData: null,
          });
        });
      }
    }
  },
  receiveNoti: (noti) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTIFICATION,
      data: noti,
    }),
  sendRequest:
    (url, data = null, callBack = () => {}) =>
    (dispatch, getState) => {
      const { IOSocket } = getState().Notification;
      const { idToken } = getState().Auth;
      const headers = { ...defaultHeaders };
      // console.log('REQUEST URL===>');
      // console.log(url);
      // console.log('REQUEST data===>');
      // console.log(data);
      if (idToken && headers) headers.Authorization = `Bearer ${idToken}`;
      const optionsReq = {
        url,
        data,
        method: "POST",
        headers,
      };
      if (IOSocket) {
        IOSocket.request(optionsReq, (resData) => {
          // console.log('Sails responded with: ', resData);
          if (resData && resData.message === "Unauthorised") {
            Notification("error", "Session expired, Please login again");
            setTimeout(() => {
              dispatch(authActions.logout());
            }, 500);
          } else {
            callBack(resData);
          }
        });
      }
    },
  disconnect: () => (_, getState) => {
    const IOSocketVar = getState().Notification.IOSocket;
    if (IOSocketVar) {
      IOSocketVar.disconnect();
    }
  },
};
export default actions;
