import { notification } from 'antd';
import { has, isObject } from 'lodash';
import { store } from '@iso/redux/store';

const errMsg = 'Error';
const successMsg = 'Success';
const infoMsg = 'Info';

const createNotification = (type, description) => {
  const state = store ? store.getState() : {};
  let msg = '';
  if (type === 'error') msg = errMsg;
  else if (type === 'success') msg = successMsg;
  else if (type === 'info') msg = infoMsg;

  const allWords =
    isObject(state) && has(state, 'LanguageSwitcher.allWords')
      ? state.LanguageSwitcher.allWords
      : {};
  const transMsg = isObject(allWords) && allWords[msg] ? allWords[msg] : msg;

  notification[type]({
    message: transMsg,
    description,
    key: transMsg,
  });
};
export default createNotification;
