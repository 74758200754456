/*firebase daniel start*/
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LetsGoShortLogo from "@iso/assets/images/LetsGoShortLogo.svg";

import "firebase/messaging";
import { store } from "@iso/redux/store";
import authActions from "@iso/redux/auth/actions";

const PushNotification = (props) => {
  const { setNotify } = props;
  const firebaseConfig = {
    apiKey: "AIzaSyAyWvigq9TTJwChGKWY7O4C8vgY2YXZCPE",
    authDomain: "letgo-app-415106.firebaseapp.com",
    projectId: "letgo-app-415106",
    storageBucket: "letgo-app-415106.appspot.com",
    messagingSenderId: "293829980257",
    appId: "1:293829980257:web:7d013a1a0551ca751c3a20",
  };

  const fapp = initializeApp(firebaseConfig);
  const messaging = getMessaging(fapp);
  //   const { accessToken } = useSelector((state) => state.auth);
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  console.log("🚀 ~ PushNotification ~ isLoggedIn:", isLoggedIn);

  const requestForFCMToken = async () => {
    console.log("helllooo=============>");

    getToken(messaging, {
      vapidKey:
        "BNZSPOlvXqKr5emtJY94qpgRjDR-YgGrqtaa___LDMdZPnp1KktQwzs4iN3WyORzt1dNdYQgr-snN9Ab31nQYZA",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("then ~ currentToken ->=============>", currentToken);
          store.dispatch(authActions.setUUID(currentToken));
        } else {
          // Show permission request UI
        }
      })
      .catch((err) => {
        console.log("notValidKey==============>", err);
      });
  };

  const ReceiveMsg = () => {
    onMessage(messaging, (payload) => {
      const notification = new Notification(payload?.notification?.title, {
        body: payload?.notification?.body,
        icon: LetsGoShortLogo,
        onclick: () => {
          // Add clickAction property
          const link = payload?.fcmOptions?.link; // Extract link from data payload
          console.log("🚀 ~ onMessage ~ link:", link);

          if (link) {
            window.open(link, "_blank"); // Open link in new tab
          } else {
            console.warn(
              "Notification does not contain a 'link' property in data payload."
            );
          }
        },
      });
      console.log(
        "🚀 ~ onMessage ~ notification:=============>",
        payload,
        notification
      );
      if (isLoggedIn !== null) {
        notification.addEventListener("click", () => {
          window.open("https://admin.letsgoplan.com/dashboard", "_blank");
          notification.close();
        });
        setNotify({
          view: true,
          msg: payload?.notification,
        });
      } else {
        notification.addEventListener("click", () => {
          window.open("https://admin.letsgoplan.com/signin", "_blank");
          notification.close();
        });
      }
      // setTimeout(() => notification.close(), 10 * 1000);
    });
  };

  const AskNotificationPermission = () => {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      // alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "granted") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          requestForFCMToken();
        } else {
        }
      });
    } else if (Notification.permission === "granted") {
      requestForFCMToken();
      ReceiveMsg();
    }
  };
  useEffect(() => {
    AskNotificationPermission();
  }, [isLoggedIn]);
};

export default PushNotification;
