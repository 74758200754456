import React, { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import { isEmpty, isObject } from "lodash";
import { IntlProvider, addLocaleData } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";
import themes from "@iso/config/theme/theme.config";
import { fixAutocomplete } from "@iso/lib/helpers/utility";
// import getApiData from '@iso/lib/helpers/apiHelper';
import locales from "@iso/config/staticData/locales.json";
// import langActions from '@iso/redux/languageSwitcher/actions';
import appActions from "@iso/redux/app/actions";
import AppLocale from "@iso/config/translation";

// const { setAllWords } = langActions;
const { setCommonData } = appActions;

// Disable chrome in-built autocomplete for all inputs
fixAutocomplete();

export default function AppProvider({ children }) {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.LanguageSwitcher.language);
  const { themeName } = useSelector(
    (state) => state.ThemeSwitcher.changeThemes
  );

  const [locale, setLocale] = useState(null);

  // const langCode =
  //   language && language.lang_code ? language.lang_code.toLowerCase() : "en";

  const langCode = "en";
  console.log("🚀 ~ AppProvider ~ langCode:", langCode, language);

  const localMsgs =
    isObject(AppLocale[langCode]) && isObject(AppLocale[langCode].messages)
      ? AppLocale[langCode].messages
      : {};

  const lCode = langCode !== "en" ? locales[langCode] : "";

  useEffect(() => {
    dispatch(setCommonData());
  }, []);

  function getMsgs(type) {
    if (!isEmpty(localMsgs)) {
      if (type === "code") return langCode;
      return localMsgs;
    }
    if (type === "code") return "en";
    return AppLocale.en.messages;
  }

  async function addLocale() {
    try {
      const localeData = await import(`react-intl/locale-data/${langCode}`);
      if (localeData && localeData.default) {
        addLocaleData(localeData.default);
      }
      if (lCode) {
        const resLocale = await import(`antd/es/locale/${lCode}.js`);
        if (resLocale && resLocale.default) {
          setLocale(resLocale.default);
        }
      }
    } catch (err) {
      // console.log(err);
    }
  }

  useEffect(() => {
    addLocale();

    // Fetch all translated words of selected language
    // Uncomment when you want to fetch translard words from API
    // async function fetchWords() {
    //   try {
    //     const res = await getApiData('trans/getAllWords', { langCode }, 'POST');
    //     if (res.success && isObject(res.data)) {
    //       setMessages(res.data);
    //       dispatch(setAllWords(res.data));
    //     }
    //     // Uncomment when all words api will be ready
    //     // addLocale();
    //   } catch (err) {
    //     // console.log(err);
    //   }
    // }
    // fetchWords();
  }, [langCode]);

  const code = getMsgs("code");
  return (
    <ConfigProvider
      getPopupContainer={(node) => {
        // Scroll dropdown along parent node
        if (node && node.className === "ant-select-selector") {
          return node.parentNode;
        }
        return document.body;
      }}
      locale={code !== "en" && locale ? locale : undefined}
    >
      <IntlProvider locale={code} messages={getMsgs()}>
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
