import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import GlobalStyles from "@iso/assets/styles/globalStyle";
import { store } from "@iso/redux/store";
import Boot from "@iso/redux/boot";
import Routes from "./router";
// import firebase from "./firebase";
// import Notification from "@iso/components/Notification";
import AppProvider from "./AppProvider";
import PushNotification from "./containers/PushNotification";
import { notification } from "antd";

function App() {
  const [notiFy, setNotify] = useState({
    view: false,
    msg: {},
  });
  console.log("🚀 ~ App ~ notiFy:", notiFy);
  useEffect(() => {
    window.process = {
      ...window.process,
    };
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        console.log("===>>>>", "hello");
        navigator.serviceWorker.register("firebase-messaging-sw.js").then(
          function (registration) {
            console.log("🚀 ~ registration:", registration);
          },
          function (error) {}
        );
      });
    }
  }, []);

  useEffect(() => {
    if (notiFy?.view) {
      notification.open({
        type: "info",
        message: notiFy?.msg?.title,
        description: notiFy?.msg?.body,
      });
      setNotify({
        view: false,
        msg: {},
      });
    }
  }, [notiFy?.view]);

  return (
    <Provider store={store}>
      <AppProvider>
        <>
          <GlobalStyles />
          <Routes />
          <PushNotification setNotify={setNotify} />
        </>
      </AppProvider>
    </Provider>
  );
}
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
